<template>
  <div class="coupon-cont wd-shadow">
<!--    <el-card>-->
      <div v-if="$store.getters.userInfo.usertype==='2'" class="add">
        <div class="subSearch">
        <el-form :inline=true>
          <el-form-item label="账号：">
            <el-input  v-model="subAccount" placeholder="请输入账号">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"  icon="el-icon-search" @click="searchByusername">查询</el-button>
          </el-form-item>
        </el-form >
      </div>
        <el-table
            v-loading="loading"
            :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
            style="width: 100%">
          <el-table-column
              label="用户名"
              prop="username">
          </el-table-column>
          <el-table-column
              label="姓名"
              prop="name">
          </el-table-column>
          <el-table-column
              label="字符余量"
              prop="remainingchars">
          </el-table-column>
          <el-table-column
              label="已使用字符"
              prop="usedamount">
          </el-table-column>
          <el-table-column
              align="right">
            <template slot="header" slot-scope="scope">
              <!--          <el-input-->
              <!--              v-model="search"-->
              <!--              size="mini"-->
              <!--              placeholder="输入关键字搜索"/>-->
              <el-button type="primary" icon="el-icon-plus" @click="add">新增子账户</el-button>
            </template>
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="position">
          <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="page.pageNo"
              :page-size="page.pageSize"
              layout="total, prev, pager, next"
              :total="page.total">
          </el-pagination>
        </div>

        <!--添加子账户弹框-->
        <!--添加子账户弹框-->
        <el-dialog v-loading="loadingWindow" title="添加子账户" :visible.sync="dialogFormVisibleAdd" @close="close">
          <el-form :model="addForm" status-icon label-width="100px" class="demo-ruleForm">
            <el-form-item label="用户名" inline-message="Z">
              <el-input v-model="addForm.username" @blur="handleUsernameCheck" :oninput = "addForm.username = addForm.username.replace(/[\u4e00-\u9fa5]{0,}$/g,'').replace(/\s+/g,'')"></el-input>
              <span class="tishi">{{this.message1}}</span>
            </el-form-item>
            <el-form-item label="姓名" >
              <el-input v-model="addForm.name" :oninput="addForm.name = addForm.name.replace(/\s+/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input placeholder="请输入密码" v-model="addForm.password" show-password auto-complete="new-password"  :oninput="addForm.password = addForm.password.replace(/\s+/g,'')" @blur="handlepwdCheck"></el-input>
              <span class="tishi">{{ this.message2 }}</span>
            </el-form-item>
            <el-form-item label="字符量">
              <!--          <el-input-number v-model="addForm.remainingchars" :min="1"></el-input-number>-->
              <el-input-number  v-model="addForm.remainingchars" controls-position="right" @change="handleChange" :min="0":precision="0"
                                :formatter="limitNumber" :parser="limitNumber"></el-input-number>
              <span class="charSum">可分配字符：{{ this.charSum }}</span>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisibleAdd = false">取消</el-button>
            <el-button type="primary" @click="addSub">添加</el-button>
          </div>
        </el-dialog>

        <!--修改子账户弹框-->
        <el-dialog v-loading="loadingWindow"  title="修改子账户" :visible.sync="dialogFormVisible" @close="close">
          <el-form :model="addForm" status-icon label-width="100px" class="demo-ruleForm">
            <el-form-item label="用户名" >
              <el-input v-model="addForm.username" disabled></el-input>
            </el-form-item>
            <el-form-item label="姓名" >
              <el-input v-model="addForm.name" :oninput="addForm.password = addForm.password.replace(/\s+/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input placeholder="修改密码，不输入则不修改" v-model="addForm.password" show-password auto-complete="new-password" :oninput="addForm.password = addForm.password.replace(/\s+/g,'')" @blur="handlepwdCheck"></el-input>
<!--              <span class="tishi">{{ this.message2 }}</span>-->
            </el-form-item>
            <el-form-item label="字符余量">
              <el-input v-model="addForm.remainingchars" disabled></el-input>
              <!--              <span class="tishi">{{ this.message2 }}</span>-->
            </el-form-item>
            <el-form-item label="预调整字符量">
              <!--        <el-input-number v-model="addForm.remainingchars" :min="1"></el-input-number>-->
              <el-input-number  v-model="charCount" controls-position="right":precision="0"
                                :formatter="limitNumber" :parser="limitNumber"></el-input-number>
              <span class="charSum">(正数将为当前账户增加字符，负数为减少字符)</span>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button  @click="dialogFormVisible = false">取消</el-button>
            <el-button type="primary" @click="updateSub">确认修改</el-button>
          </div>
        </el-dialog>
      </div>
      <div v-if="$store.getters.userInfo.usertype!=='2'">
        <div id="no-message" class="no-message">
          <i class="wd-center"></i>
          <p>您还不是企业账户，<el-link type="primary" @click="goBuy">去开通？</el-link></p>
          <p style="color: #FF5722;">累计充值1000元以上自动升级为企业用户！</p>
        </div>
      </div>
<!--    </el-card>-->

  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
export default {
  name: "SubAccount",
  data() {
    return {
      loading:true,
      loadingWindow:true,
      subAccount:'',
      message1:'',
      message2:'',
      tableData: [],
      search: '',
      dialogFormVisibleAdd:false,
      dialogFormVisible:false,
      addForm:{
        username:'',
        name:'',
        password:'',
        remainingchars:'',
        // superenterid:'',
      },
      charCount:0,
      //字符总和
      charSum:0,
      page:{
        total:0,
        pageNo:1,
        pageSize:7,
      }
    }
  },
  methods: {
    searchByusername(){
      this.loading=true;
        if(this.subAccount===''){
          this.refresh();
        }else{
          let params={
            username:this.subAccount,
          }
          axios.post("/api/customer/chatCustomer/queryByUsername",params).then(res=>{
            if(res.data.success){
                this.tableData=[];
                this.tableData[0]=res.data.result;
                this.page.total=1;
              this.$message({
                message:res.data.message,
                type:'success'
              })
            }else{
              this.tableData=[];
              this.page.total=0;
              this.$message({
                message:res.data.message,
                type:'warning'
              })
            }
            this.loading=false;
          })
        }
    },
    goBuy(){
        this.$router.push({path:'/members'})
    },
    handleCurrentChange(){
      this.loading=true;
      var parms={
        id:this.$store.getters.userInfo.id,
        pageNo:this.page.pageNo,
        pageSize:this.page.pageSize,
      }
      axios
          .post("/api/customer/customerMng/querySub",parms).then(res=> {
        // console.log("id"+this.$store.getters.userInfo.id)
        if (res.data.success) {
          // console.log("====="+res.data.result)
          // console.log(res.data.result.data)
          this.tableData = res.data.result.data;
          this.page.total = res.data.result.total;
        }
        this.loading=false;
      })
    },
    refresh(){
      this.loading=true;
      var parms={
        id:this.$store.getters.userInfo.id,
        pageNo:this.page.pageNo,
        pageSize:this.page.pageSize,
      }
      axios
          .post("/api/customer/customerMng/querySub",parms).then(res=> {
        // console.log("id"+this.$store.getters.userInfo.id)
        if (res.data.success) {
          // console.log("====="+res.data.result)
          // console.log(res.data.result.data)
          this.tableData = res.data.result.data;
          this.page.total = res.data.result.total;
        }
        this.loading=false;
      })
    },
    handleUsernameCheck() {
      // this.addForm.username='Z'+this.addForm.username;
      if(this.addForm.username===null||this.addForm.username===''){
        // callback(new Error("请输入正确的邮箱号"))
        this.message1="账号不能为空"
      }else if(this.addForm.username.length<6){
        this.message1="账号长度至少为6位"
      }else{
        this.message1=''
        var params = {
          username: this.addForm.username,
        };
        axios.post("/api/customer/customerMng/queryUsername",params).then(res=>{
          // console.log("1111")
          if(res.data.success){
            this.message1='';
          }else{
            this.message1="账号已存在"
          }
        })
      }
    },
    handlepwdCheck(){
      // console.log(1111)
      if(this.addForm.password===null||this.addForm.password===''){
        // console.log(2222)
        this.message2=this.$t("creatPage.message2")
      }else{
        this.message2='';
      }
    },
    //分配字符触发事件
    handleChange(){
      //alert(this.addForm.remainingchars + " : " + this.charSum)
      if(this.addForm.remainingchars>this.charSum){
        //this.addForm.remainingchars=this.charSum;
        this.$set(this.addForm, 'remainingchars', this.charSum);
        this.$message({
          message:'字符余量不足以分配',
          type:"warning",
        })
      }
    },
    //打开修改窗口
    handleEdit(index, row) {
      this.loadingWindow=true;
      axios.get("/api/customer/customerMng/queryCustomer?id="+this.$store.getters.userInfo.id).then(res=>{
        if(res.data.success){
          this.$store.commit('handleUserInfo',res.data.result);
          this.charSum=this.addForm.remainingchars+this.$store.getters.userInfo.remainingchars;
        }
        this.loadingWindow=false;
      })
      this.dialogFormVisible=true;
      this.addForm=this.tableData[index];

      this.addForm.password='';
      this.charCount=0;
    },
    //删除操作
    handleDelete(index, row) {
      console.log(index, row);
      this.addForm=this.tableData[index];
      this.$confirm('此操作将永久删除该账户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var param={
          subId:this.addForm.id,
        }
        axios.post("/api/customer/chatCustomer/deleteSub",param).then(res=>{
          if(res.data.success){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message.error(res.data.message);
          }
          // this.dialogFormVisibleAdd=false;
          // location.reload()
          this.refresh()
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // cancle(){
    //   this.dialogFormVisible=false;
    //   location.reload()
    // },
    close(){
      this.dialogFormVisible=false;
      this.dialogFormVisibleAdd=false;
      // location.reload()
      this.refresh();
    },
    //打开添加子账户窗口
    add(){
      this.loadingWindow=true;
      // console.log(123)
      axios.get("/api/customer/customerMng/queryCustomer?id="+this.$store.getters.userInfo.id).then(res=>{
        if(res.data.success){
          this.$store.commit('handleUserInfo',res.data.result);
          this.charSum=this.$store.getters.userInfo.remainingchars;
        }
        this.loadingWindow=false;
      })
      this.addForm.password='';
      this.addForm.remainingchars='';
      this.addForm.name='';
      this.addForm.username='';
      this.dialogFormVisibleAdd = true;
    },
    //添加/按钮
    addSub(){
      if(this.addForm.username===''){
        this.$message({
          message: '账号不能为空',
          type: 'warning'
        });
      }else if(this.addForm.name===''){
        this.$message({
          message: '姓名不能为空',
          type: 'warning'
        });
      }else if(this.addForm.password===''){
        this.$message({
          message: '密码不能为空',
          type: 'warning'
        });
      }else if(this.addForm.remainingchars===0){
        this.$message({
          message: '请为账户分配字符',
          type: 'warning'
        });
      }else if(this.addForm.username.length<6){
        this.$message({
          message:'账号长度至少为6位',
          type:'warning'
        })
      }else{
        var params = {
          username: this.addForm.username,
        };
        axios.post("/api/customer/customerMng/queryUsername",params).then(res=>{
          // console.log("1111")
          if(res.data.success){
            var subAccount={
              username:this.addForm.username,
              name:this.addForm.name,
              password:this.addForm.password,
              charCount:this.addForm.remainingchars,
            }
            axios.post("/api/customer/chatCustomer/addSubAccount",subAccount).then(res=>{
              if(res.data.success){
                this.$message({
                  message: '操作成功',
                  type: 'success'
                });
                // this.dialogFormVisibleAdd=false;
                // // location.reload()
                // this.refresh();
                this.close();
              }else{
                this.$message.error(res.data.message);
              }
            })
          }else{
            this.$message({
              message: '账户已存在',
              type: 'warning'
            });
          }
        })

      }

    },
    updateSub(){
      if(this.charCount==0){
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.close();
      }else if(this.addForm.name===''){
        this.$message({
          message: '姓名不能为空',
          type: 'warning'
        });
      }else{
        var param={
          name:this.addForm.name,
          subId:this.addForm.id,
          charCount:this.charCount,
        }
        if(this.addForm.password!==null||this.addForm.password!==''){
          param.newPass=this.addForm.password;
        }
            axios.post("/api/customer/chatCustomer/updateSub",param).then(res=>{
              if(res.data.success){
                this.$message({
                  message: '操作成功',
                  type: 'success'
                });
                // this.dialogFormVisibleAdd=false;
                // // location.reload()
                // this.refresh();
                this.close();
              }else{
                this.$message.error(res.data.message);
              }
            })
      }

    },
    // methods 正则替换小数点
    limitNumber(value) {
      if (typeof value === 'string') {
        return !isNaN(Number(value)) ? value.replace(/\./g, '') : 0
      } else if (typeof value === 'number') {
        return !isNaN(value) ? String(value).replace(/\./g, '') : 0
      } else {
        return 0
      }
    }
  },
  created() {
    this.loading=true;
    // console.log("用户类型"+this.$store.getters.userInfo.usertype)
    if(this.$store.getters.userInfo.usertype==='2'){
      // this.addForm.superenterid=this.$store.getters.userInfo.id;
      // console.log("yonghu1")
      var parms={
        id:this.$store.getters.userInfo.id,
        pageNo:this.page.pageNo,
        pageSize:this.page.pageSize,
      }
      axios
          .post("/api/customer/customerMng/querySub",parms).then(res=>{
        // console.log("id"+this.$store.getters.userInfo.id)
        if(res.data.success){
          // console.log("====="+res.data.result)
          // console.log(res.data.result.data)
          this.tableData=res.data.result.data;
          this.page.total=res.data.result.total;
        }
        this.loading=false;
      })
    }
  }
}
</script>

<style scoped>
.position{
  top: 10px;
}
.coupon-cont {
  display: block;
  float: left;
  width: 905px;
  margin-left: 15px;
  margin-bottom: 40px;
  background: #fff;
  border-radius: 4px;
}
.add {
  flex: 1;
  padding-top: 40px;
}
.tishi{
  color: #F83333;
  font-size: 12px;
}
.charSum{
  color: #F83333;
}
.no-message i {
  width: 100px;
  height: 100px;
  background: url(../../../../public/static/common/img/common-icon.png) -419px -4px no-repeat;
  border-radius: 50%;
  display: block;
  margin-top: 100px;
}
.no-message {
  box-sizing: border-box;
  width: 866px;
  float: left;
  margin-bottom: 200px;
}
.wd-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.no-message p {
  height: 16px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #666;
  line-height: 24px;
  display: block;
  text-align: center;
  margin-top: 20px;
}
.subSearch{
  display: block;
  float: left;
  width: 855px;
  height: 45px;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  background: #fff;
  border-radius: 4px;
}
</style>
